















import { Component, Prop, Vue } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class Back extends Vue {
    @Prop() back_url?: string
    @Prop() page_name?: string
}
